import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./components/form/Signin";
import SignUp from "./components/form/SignUp";
import Cart from "./components/pages/Cart";
import Home from "./components/pages/Home";

// custom stylings
import "./components/styles/Styles.css"

//redux - state management
import { createStore } from "redux";
import CartReducer from "./components/reducer/CartReducer";
import { Provider } from "react-redux";
import NavBar from "./components/inc/NavBar";
import ProtectefRoutes from "./components/misc/ProtectefRoutes";

function App() {
  const store = createStore(CartReducer);

  const kinsmenUser = localStorage.getItem("kinsmenUser");
  // !kinsmenUser && alert("No Active User! Sign In")

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="parent-container">
          <NavBar kinsmenUser={kinsmenUser} />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectefRoutes kinsmenUser={kinsmenUser}>
                  <Home />
                </ProtectefRoutes>
              }
            />
            <Route path="cart" element={<Cart />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="signin" element={<Signin />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
