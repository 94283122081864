import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function NavBar() {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: "USER_STATE", payload: false });
  };
  return (
    <div className="nav-wrapper">
      <nav>
        <div className="nav-contents-wrapper">
          <div className="brand">
            <h1>
              {" "}
              <Link to="/" style={{ color: "white" }}>
                Brand
              </Link>
            </h1>
          </div>

          <div className="brand-caption">
            <h2>
              <Link to="/" style={{ color: "white" }}>
                Luxury Collections
              </Link>
            </h2>
          </div>

          {/* conditionally displaying cart and logout */}
          {store.isUserAvailable && (
            <div className="cart">
              <h3>
                <Link to="./cart" style={{ color: "white" }}>
                  Cart
                  {store.cart.length === 0 ? (
                    <></>
                  ) : (
                    <span id="cart-badge">{store.cart.length}</span>
                  )}
                </Link>
              </h3>

              <button
                style={{
                  color: "white",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              >
                <Link to="/signin">&nbsp; &nbsp; LogOut</Link>
              </button>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
