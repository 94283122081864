import React, { useEffect, useState } from 'react'
import {useSelector } from 'react-redux'

function HomeView({ product }) {
    const [btnText, setBtnText] = useState('Add To Cart')
    const [bgcolor, setBgColor] = useState("red")
    const store = useSelector(store => store)


    useEffect(() => {
        let inCart = store.cart.find((item) => item.id === product.id)
        if (inCart) {
            setBtnText("In Cart")
            setBgColor('yellowgreen')
        }
    }, [])


    return (
        <div className='single-product'>
            <div className='image'><img src={product.imageURL} alt={product.name}/></div>

            <div className='divider'></div>

            <div className='product-details-wrapper'>
                <div className='product-details'>
                    <h4>{product.name}</h4>
                    <h5>{product.price}</h5>
                </div>
                <button style={{ backgroundColor: bgcolor }} className='to-cart-btn'>{btnText}</button>
            </div>
        </div >
    )
}

export default HomeView