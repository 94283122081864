import React from "react";
import { Navigate } from "react-router-dom";

function ProtectefRoutes({ children }) {
  const kinsmenUser = localStorage.getItem("kinsmenUser");
  if (kinsmenUser) {
    return children;
  } else {
    return <Navigate to="/signin" />;
  }
}
export default ProtectefRoutes;
